import { consoleI18n } from '@ax/console-i18n';
import { RawLocation } from 'vue-router';
import { DATE_TIME_ZONE_FORMAT } from '@ax/date-time-utils';
import { format } from 'date-fns';
import { StackedBarChartDataItem } from '@ax/cosmos/components/Charts';
import { ref } from '@vue/composition-api';
import { User } from '@ax/data-services-user/models/user';
import { useTracking } from '@/compositions/useTracking';
import { PolicyResults, PolicyStatistic } from '@/models/policy-results';

/**
 * Converts the policy result key returned from the backend to a readable translated string.
 * If we do not have a translated string in our translations, just return the value from the backend.
 *
 * @param statistic The statistic key from the backend.
 * @returns The readable translated statistic key.
 */
export function getPolicyResultText(statistic: PolicyStatistic): string {
  if (statistic === 'compliant' || statistic === 'success') {
    return consoleI18n.t('general.statuses.success');
  }

  return consoleI18n.te(`general.statuses.${statistic}`)
    ? consoleI18n.t(`general.statuses.${statistic}`)
    : statistic;
}

export type PolicyResultDataLinks = Partial<
  Record<PolicyStatistic, RawLocation>
>;

/**
 * Used internally to this file to populate individual data links for the policy results
 * @param {string} orgIdString the orgId
 * @param {string} policyUuid The policy UUID
 * @param result the policy result (one or more of 'success', 'pending', or 'failed')
 * @returns a RawLocation object
 */
export function getPolicyResultsDataLink(
  orgIdString: string,
  policyUuid: string,
  result: 'success' | 'pending' | 'failed' | 'compliant',
): RawLocation {
  // success includes compliant now
  let status = [result];

  if (result === 'success') {
    status = ['success', 'compliant'];
  }

  return {
    name: 'policyRunResultTab',
    query: { o: orgIdString, status },
    params: { policyUuid },
  };
}

/**
 * Used to create the data links for a specific `PolicyResults`
 * @param orgId an org's id
 * @param policyResults the `PolicyResults` to create the data links for
 * @returns `Record<'success' | 'pending' | 'failed', RawLocation>
 */
export function getPolicyResultsDataLinks(
  orgId: number,
  policyResults: PolicyResults,
) {
  const orgIdString = `${orgId}`;
  const policyUuid = `${policyResults.uuid}`;
  return {
    success: getPolicyResultsDataLink(orgIdString, policyUuid, 'success'),
    pending: getPolicyResultsDataLink(orgIdString, policyUuid, 'pending'),
    failed: getPolicyResultsDataLink(orgIdString, policyUuid, 'failed'),
  };
}

/**
 * A function that creates the metadata object for a stacked bar chart item
 * @param {number} orgId the org id
 * @param policyResults `PolicyResults`
 * @returns metadata related to the `PolicyResults`
 */
export function getPolicyResultsMetadata(
  orgId: number,
  policyResults: PolicyResults,
) {
  return {
    title: {
      name: policyResults.name,
      count: consoleI18n.tc(
        'reports.policyResults.devices',
        policyResults.total_results || 0,
        {
          n: policyResults.total_results || 0,
        },
      ),
    },
    tooltip: {
      executionTime: format(
        new Date(policyResults.execution_time),
        DATE_TIME_ZONE_FORMAT,
      ),
      type: consoleI18n.t(
        `reports.policyResults.policyType.${policyResults.type}`,
      ),
    },
    policyId: policyResults.id,
    policyUuid: policyResults.uuid,
    detailsRoute: {
      name: 'policyRunResultTab',
      query: { o: `${orgId}` },
      params: { policyUuid: `${policyResults.uuid}` },
    },
  };
}

/**
 * Use to get a formatted title for a `PolicyResults`
 * @param policyResults `PolicyResults`
 * @returns {string} formatted title for the `PolicyResults`
 */
export function getPolicyResultsTitle(policyResults: PolicyResults) {
  return `${policyResults.name} (${consoleI18n.tc(
    'reports.policyResults.devices',
    policyResults.total_results,
    { n: policyResults.total_results },
  )})`;
}

/**
 * Use to calculate the data for a specific `PolicyResults`
 * @param policyResults `PolicyResults`
 * @returns `Record<'success' | 'pending' | 'failed', number>`
 */
export function getPolicyResultsData(policyResults: PolicyResults) {
  return {
    success: policyResults.total_success ?? 0,
    pending: policyResults.statistics.pending ?? 0,
    failed: policyResults.statistics.failed ?? 0,
  };
}

/**
 * Given a list of result types, specify filters to hide from the policy results set.
 * @param results The result types to show from the dataset
 * @returns `Result types to filter out of the dataset`
 */
export function getHiddenPolicyResults(results: string[]): PolicyStatistic[] {
  const resultsFilters = getPolicyResultsFilters();
  const hiddenResults = resultsFilters.filter(
    (val) => results.indexOf(val) === -1,
  );

  return hiddenResults;
}

/**
 * Returns the list of possible PolicyStatistics to filter by
 * @returns `Possible PolicyStatistics to filter by`
 */
export function getPolicyResultsFilters(): PolicyStatistic[] {
  return ['success', 'pending', 'failed'];
}

/**
 * Use to create a function that will map a `PolicyResults` into a `StackedBarChartItem`
 * @param orgId The current orgId
 * @returns `(policyResults: PolicyResults) => StackedBarChartDataItem`
 */
export function getPolicyResultsStackedBarChartItem(orgId: number) {
  return (policyResults: PolicyResults): StackedBarChartDataItem => ({
    data: getPolicyResultsData(policyResults),
    dataLinks: getPolicyResultsDataLinks(orgId, policyResults),
    metadata: getPolicyResultsMetadata(orgId, policyResults),
    title: getPolicyResultsTitle(policyResults),
  });
}

/**
 * Segment event to track routing events to the policy result details page (used by both the
 * stacked bar chart and table)
 * @param user The current org user
 */
export function trackPolicyDetailsRoute(user: User) {
  // since this is called from the template, the user is not a ref, but
  // since useTracking expects a ref we have to re-wrap it
  const { track } = useTracking({ user: ref(user) });
  track(`Click: Policy Details Link`, 'Policy Details Page');
}

export const GROUP_NAME_REQUIRED_ERROR = 'Group name is required.';

export const GROUP_NAME_MAX_LENGTH = 45;

export const GROUP_NAME_MAX_LENGTH_ERROR = `The name cannot exceed ${GROUP_NAME_MAX_LENGTH} characters.`;

export function getGroupNameValidationErrors(
  isDirty: boolean,
  failsRequired: boolean,
  failsMaxLength: boolean,
) {
  const errors: string[] = [];

  if (isDirty) {
    return errors;
  }

  if (failsRequired) {
    errors.push(GROUP_NAME_REQUIRED_ERROR);
  }
  if (failsMaxLength) {
    errors.push(GROUP_NAME_MAX_LENGTH_ERROR);
  }

  return errors;
}

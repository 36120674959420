import { UserPref, UserPrefs } from '@ax/data-services-user/models/user';
import { RbacRole } from '@ax/data-services-authorization/models/rbac';
import { OrganizationPrefs } from '@/models/organization';

export const emailNotificationOptions = [
  { label: 'Devices Added to Automox', value: 'notify.system.add' },
  { label: 'Devices Removed from Automox', value: 'notify.system.delete' },
  {
    label: 'Devices Successfully Patched',
    value: 'notify.system.patch.applied',
  },
  { label: 'Devices Failed to Patch', value: 'notify.system.patch.failed' },
  { label: 'Weekly Digest of Automox Activity', value: 'notify.weeklydigest' },
];
export const slackNotificationOptions = [
  { label: 'Devices Added to Automox', value: 'notify.system.add.slack' },
  {
    label: 'Devices Removed from Automox',
    value: 'notify.system.delete.slack',
  },
  {
    label: 'Devices Successfully Patched',
    value: 'notify.system.patch.applied.slack',
  },
  {
    label: 'Devices Failed to Patch',
    value: 'notify.system.patch.failed.slack',
  },
];

export const usStates = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export function updateFormNotifications(
  prefs: UserPrefs | OrganizationPrefs,
): UserPrefs | OrganizationPrefs {
  return Object.keys(prefs).reduce(
    (preferences: UserPrefs | OrganizationPrefs, key) => {
      if (key.includes('notify')) {
        return { ...preferences, [key]: prefs[key] };
      }

      return preferences;
    },
    {},
  );
}

export function arrayPrefsToObject(prefs: UserPref[]): UserPrefs {
  return prefs.reduce((preferences: UserPrefs, pref) => {
    return { ...preferences, [pref.pref_name!]: pref.value === 'true' };
  }, {});
}

export function getRbacRoleNameByOrgId(
  roles: RbacRole[] = [],
  organizationId = 0,
): string {
  const rbacRole: RbacRole | undefined = roles.find(
    (role) => role.organization_id === organizationId,
  );

  return rbacRole && rbacRole.name ? rbacRole.name : '';
}

export function getTFAType(prefs: UserPref[] = []): string {
  const tfa = prefs.find((pref) => pref.pref_name === 'user.tfa');

  switch (tfa?.value) {
    case 'google':
      return 'mobile';
    case 'email':
      return 'email';
    default:
      return '';
  }
}

export function getTFAStatus(prefs: UserPref[] = []): boolean {
  const tfaType = getTFAType(prefs);

  return tfaType === 'mobile' || tfaType === 'email';
}

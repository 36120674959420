import {
  StackedBarChartTranslations,
  LegendItemDisplayConfig,
} from '@ax/cosmos/components/Charts';
import { CosmosDarkColors } from '@ax/cosmos/utils/colors';
import { consoleI18n } from '@ax/console-i18n';

import { PolicyStatistic } from '@/models/policy-results';

/**
 * The styling (colors) and translated labels for the stacked bar chart for
 * the policy results and policy result details pages.
 */
export const stackedBarChartLegendConfig: Record<
  Exclude<PolicyStatistic, 'compliant'>,
  LegendItemDisplayConfig
> = {
  success: {
    barBgColor: CosmosDarkColors.DataLime70,
    barTextColor: CosmosDarkColors.Storm2,
    dataLabel: consoleI18n.t('general.statuses.success'),
  },
  pending: {
    barBgColor: CosmosDarkColors.DataYellow70,
    barTextColor: CosmosDarkColors.Storm2,
    dataLabel: consoleI18n.t('general.statuses.pending'),
  },
  failed: {
    barBgColor: CosmosDarkColors.DataRed60,
    barTextColor: CosmosDarkColors.Storm2,
    dataLabel: consoleI18n.t('general.statuses.failed'),
  },
};

/**
 * The static-text translations for the stacked bar chart for the policy results
 * and policy result details pages.
 */
export const stackedBarChartTranslations: StackedBarChartTranslations = {
  noResults: consoleI18n.t('global.nav.zoneSwitcher.noItems'),
};

import { reactify } from '@vueuse/core';
import { Route } from 'vue-router';

/**
 * Extract the `policyUuid` out `Route` if one exists
 * @param route The current `Route`
 * @returns `string | undefined` The policy UUID.
 */
export function getPolicyUuid(route: Route): string | undefined {
  const { policyUuid } = route.params;
  return policyUuid;
}

/**
 * Extract the current `policyUuid` out of a `Route` in a reactive manner.
 */
export const usePolicyUuid = reactify(getPolicyUuid);
